.home-intro-section {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14%;
}

.home-profile-photo img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-right: 50px;
  border: 2px solid #000000;
  z-index: 2;
  position: relative;
}

.home-profile-photo::before {
  content: "";
  position: absolute;
  height: 250px;
  width: 250px;
  border: 1px solid #000000;
  margin-left: 10px;
  margin-top: 10px;
}

.home-brief-intro {
  text-align: left;
}

.home-brief-intro h1 {
  font-weight: 100;
  font-size: xx-large;
}

.intro-name {
  background-color: #000;
  padding: 5px 10px;
  color: #ffffff;
  transition: ease-in-out 1000ms;
}

.home-brief-intro p {
  font-weight: 100;
  font-size: large;
  color: #5b5959
}
@media (max-width: 768px) {
  .home-intro-section {
    width: 80%;
    margin-top: 25%
  }

  .home-brief-intro h1 {
    line-height: 50px;
  }

  .home-brief-intro p {
    line-height: 27px;
  }
}

@media screen and (max-width: 768px) {
  .home-intro-section {
    flex-wrap: wrap;
  }
}