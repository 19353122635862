@import url('https://fonts.googleapis.com/css?family=Muli|Nunito|Inria+Serif|Solway|Cabin:600&display=swap');

html {
  scroll-behavior: smooth;
}

* {
box-sizing: border-box;
}

/* @font-face {
  font-family: 'Solway';
  font-family: 'Nunito';
  src: url('https://fonts.googleapis.com/css?family=Nunito|Solway&display=swap'); /* IE9 Compat Modes */
/* } */

body{
  font-family: 'Inria Serif', serif;
  margin: 0;
}

a, a:visited {
  color: #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}
