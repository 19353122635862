.portfolio-section {
  width: 60%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: left;
}
.portfolio-section p {
  font-weight: 100;
  font-size: large;
  color: #5b5959
}

.portfolio-section h1 {
  font-weight: 100;
  font-size: xx-large;
}

.portfolio-section h2 {
  font-weight: 100;
}

.portfolio-projects {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-columns: auto auto;
  width: 100%
}

.project-item {
  padding: 0 0 10px 0;
  margin-top: 10px;
  border-bottom: 1px solid #efefef;
}

.project-item img{
  width: 100%;
  background-color: #efefef;
}

.project-item img:hover {
  opacity: 1;
  filter: none;
  transition: ease-in-out 300ms;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
}

.project-technologies .item {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: #858484;
  font-size: small;
}

@media (max-width: 768px) {
  .portfolio-section {
    width: 80%;
    margin-top: 25%
  }

  .portfolio-projects {
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-template-columns: auto;
    width: 100%
  }
}