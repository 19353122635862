
.about-section {
  width: 60%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: left;
}

.about-section h1 {
  font-weight: 100;
  font-size: xx-large;
}

.about-section  p {
  font-weight: 100;
  font-size: large;
  color: #5b5959;
}

.about-section .goal {
  color: #2689f1;
}

.tools-and-tech {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}


.tools-and-tech img {
  width: 100px;
  height: 100px;
  margin-top:10px;
  padding: 5px;
  opacity: 0.4;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.tools-and-tech img:hover {
  opacity: 1;
  filter: none;
  transition: ease-in-out 300ms;
}


@media (max-width: 768px) {
  .about-section  {
    width: 80%;
    margin-top: 25%
  }
}