.empty-section {
  margin-top: 20%;
}

.empty-section h1 {
  font-size: 200px;
  line-height: 0;
}

.empty-section p {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .empty-section {
    margin-top: 50%;
  }
}