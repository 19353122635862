@import url(https://fonts.googleapis.com/css?family=Muli|Nunito|Inria+Serif|Solway|Cabin:600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-intro-section {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14%;
}

.home-profile-photo img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-right: 50px;
  border: 2px solid #000000;
  z-index: 2;
  position: relative;
}

.home-profile-photo::before {
  content: "";
  position: absolute;
  height: 250px;
  width: 250px;
  border: 1px solid #000000;
  margin-left: 10px;
  margin-top: 10px;
}

.home-brief-intro {
  text-align: left;
}

.home-brief-intro h1 {
  font-weight: 100;
  font-size: xx-large;
}

.intro-name {
  background-color: #000;
  padding: 5px 10px;
  color: #ffffff;
  -webkit-transition: ease-in-out 1000ms;
  transition: ease-in-out 1000ms;
}

.home-brief-intro p {
  font-weight: 100;
  font-size: large;
  color: #5b5959
}
@media (max-width: 768px) {
  .home-intro-section {
    width: 80%;
    margin-top: 25%
  }

  .home-brief-intro h1 {
    line-height: 50px;
  }

  .home-brief-intro p {
    line-height: 27px;
  }
}

@media screen and (max-width: 768px) {
  .home-intro-section {
    flex-wrap: wrap;
  }
}
.header {
  display: flex;
  justify-content: flex-start;
  font-size: small;
  /* color: #000000; */
  padding: 0 20px;
  border-bottom: 1px solid #efefef;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
}

.header-dot {
  color: #2689f1;
  font-size: xx-large;
}

.footer {
  display: flex;
  justify-content: space-between;
  bottom: 0;
  border-top: 1px solid #efefef;
  position: fixed;
  width: 60%;
  padding: 20px;
  color: black;
  background-color: #fff;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .footer {
    width: 100%;
  }
}

.about-section {
  width: 60%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: left;
}

.about-section h1 {
  font-weight: 100;
  font-size: xx-large;
}

.about-section  p {
  font-weight: 100;
  font-size: large;
  color: #5b5959;
}

.about-section .goal {
  color: #2689f1;
}

.tools-and-tech {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}


.tools-and-tech img {
  width: 100px;
  height: 100px;
  margin-top:10px;
  padding: 5px;
  opacity: 0.4;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.tools-and-tech img:hover {
  opacity: 1;
  -webkit-filter: none;
          filter: none;
  -webkit-transition: ease-in-out 300ms;
  transition: ease-in-out 300ms;
}


@media (max-width: 768px) {
  .about-section  {
    width: 80%;
    margin-top: 25%
  }
}
.portfolio-section {
  width: 60%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: left;
}
.portfolio-section p {
  font-weight: 100;
  font-size: large;
  color: #5b5959
}

.portfolio-section h1 {
  font-weight: 100;
  font-size: xx-large;
}

.portfolio-section h2 {
  font-weight: 100;
}

.portfolio-projects {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-columns: auto auto;
  width: 100%
}

.project-item {
  padding: 0 0 10px 0;
  margin-top: 10px;
  border-bottom: 1px solid #efefef;
}

.project-item img{
  width: 100%;
  background-color: #efefef;
}

.project-item img:hover {
  opacity: 1;
  -webkit-filter: none;
          filter: none;
  -webkit-transition: ease-in-out 300ms;
  transition: ease-in-out 300ms;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
}

.project-technologies .item {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: #858484;
  font-size: small;
}

@media (max-width: 768px) {
  .portfolio-section {
    width: 80%;
    margin-top: 25%
  }

  .portfolio-projects {
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-template-columns: auto;
    width: 100%
  }
}
.contact-section {
  width: 60%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: left;
}

.contact-section h1 {
  font-weight: 100;
  font-size: xx-large;
}

.contact-section  p {
  font-weight: 100;
  font-size: large;
  color: #5b5959;
}

.contact-social-icons {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.contact-social-icons img{
  width: 50px;
  opacity: 0.4;
}

.contact-social-icons img:hover{
  width: 50px;
  opacity: 1;
  -webkit-transition: ease-in-out 300ms;
  transition: ease-in-out 300ms;
}



@media (max-width: 768px) {
  .contact-section  {
    width: 80%;
    margin-top: 25%
  }

  .contact-social-icons {
    width: 80%;
  }
}
.story-item {
  border: 1px solid #686868;
  border-radius: 5px;
  padding: 15px;
  margin-top: 35px;
  text-align: left;
  position: relative;
}

.story-item img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.story-item h2 {
  cursor: pointer;
}

.story-date {
  font-size: small;
  color: #4d4d4d;
}

.story-description {
  color: #4d4d4d;
  line-height: 25px;
}

.story-categories {
display: flex;
flex-wrap: wrap;
}

.story-categories .item {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: #858484;
  font-size: small;
}
.stories-page h1 {
  font-weight: 100;
  text-align: left;
}

.stories-section {
  margin-top: 10%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .stories-section {
    width: 80%;
    margin-top: 25%;
  }
}

.empty-section {
  margin-top: 20%;
}

.empty-section h1 {
  font-size: 200px;
  line-height: 0;
}

.empty-section p {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .empty-section {
    margin-top: 50%;
  }
}
.omoge-page {
  background-color: black;
  color: white;
  padding-top: 50px;
  padding-bottom: 80px;
}

.omoge-page > h1 {
  padding-top: 20px;
}

.omoge-page .note {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  line-height: 30px;
  text-align: justify;
}

.omoge-page .bottom-section {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  line-height: 30px;
  text-align: justify;
}

.bottom-section input {
  background-color: transparent;
  border: 1px solid #efefef;
  color: #efefef
}

.omoge-page .note-from {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .omoge-page .note {
    width: 85%;
  }
  
  .omoge-page .bottom-section {
    width: 85%;
  }
}
.omoge-gallery {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 80px;
  color: #efefef;
}

.omoge-gallery .heading {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.omoge-gallery .image-section {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.gallery-image img {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-color: #efefef;
}

.gallery-description {
  text-align: left;
  font-size: small;
  line-height: 20px;
}

@media screen and (max-width: 768px) {
  .omoge-gallery .heading {
    width: 85%;
  }
  
  .omoge-gallery .image-section {
    width: 85%;
  }
}

.omoge-music {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 80px;
  color: #efefef;
}

.omoge-music .heading {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.omoge-playlist {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.omoge-playlist img {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-color: #efefef;
  -webkit-transition: padding .5s;
  transition: padding .5s;
}

.omoge-playlist .her-song {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 10px;
  -webkit-filter: grayscale();
          filter: grayscale();
  background-color: #efefef;
  -webkit-transition: padding .5s;
  transition: padding .5s;
}

.omoge-playlist img:hover {
  padding-top: 5px;
}

@media screen and (max-width: 768px) {
  .omoge-music .heading {
    width: 85%;
  }
  .omoge-playlist {
    width: 85%;
  }
}
.omoge-birthday {
  color: #ffffff;
  min-height: 100vh;
  padding: 50px
}

.omoge-birthday .card-container {
  width: 50%;
  margin: 0 auto;
  border: 5px solid #ffffff;
  cursor: pointer;
}
.omoge-birthday .card-container img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .omoge-birthday .card-container {
    width: 70%;
  }
}
.aduke-page {
  background-color: black;
}

/* .btn-next {
  background-color: transparent;
  border: 1px solid #efefef;
  color: #efefef
} */

.omoge-birthday {
  /* height: 100%; */
  /* padding-bottom: 60px; */
}

.omoge-birthday .heading {
  /* position: absolute; */
  /* top: 30%; */
  /* left: 50%; */
  /* transform: translate(-50%, 50%); */
  width: 60%;
  margin: 0 auto;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 768px) {
  .omoge-birthday .heading{
    width: 90%;
  }
}

.omoge-birthday .heading h1 {
  font-size: 50px;
}

.balloon {
  height: 60px;
  width: 55px;
  background-color: crimson;
  position: absolute;
  top: 40%;
  left: 10%;
  border-radius: 50%;
  -webkit-animation: move 2s ease-in infinite;
          animation: move 2s ease-in infinite;
}

.balloon::before {
  content: "";
  height: 60px;
  width: 4px;
  position: absolute;
  background-color: white;
  top: 93%;
  left: 45%;
}

@-webkit-keyframes move {
  0% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
  
}

@keyframes move {
  0% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
  
}

.balloon:nth-child(2) {
  left: 15%;
  top: 40%;
  background-color: green;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}
.balloon:nth-child(3) {
  left: 30%;
  top: 40%;
  background-color: lightpink;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}
.balloon:nth-child(4) {
  left: 40%;
  top: 40%;
  background-color: gray;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}
.balloon:nth-child(5) {
  left: 5%;
  top: 40%;
  background-color: orange;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}
.balloon:nth-child(6) {
  left: 10%;
  top: 40%;
  background-color: crimson;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}
.balloon:nth-child(7) {
  left: 15%;
  top: 40%;
  background-color: yellow;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}
.balloon:nth-child(8) {
  left: 20%;
  top: 40%;
  background-color: blue;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}
.balloon:nth-child(9) {
  left: 25%;
  top: 40%;
  background-color: aquamarine;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
} 
.balloon:nth-child(10) {
  left: 30%;
  top: 40%;
  background-color: aquamarine;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
} 
.balloon:nth-child(11) {
  left: 35%;
  top: 40%;
  background-color: palegoldenrod;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
} 
.balloon:nth-child(12) {
  left: 40%;
  top: 40%;
  background-color: oldlace;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
} 
.balloon:nth-child(13) {
  left: 45%;
  top: 40%;
  background-color: ivory;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
} 
.balloon:nth-child(14) {
  left: 53%;
  top: 40%;
  background-color: yellow;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
} 
.balloon:nth-child(15) {
  left: 57%;
  top: 40%;
  background-color: thistle;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
} 
.balloon:nth-child(16) {
  left: 63%;
  top: 40%;
  background-color: tan;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
} 
.balloon:nth-child(17) {
  left: 67%;
  top: 40%;
  background-color: rosybrown;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
} 
.balloon:nth-child(18) {
  left: 73%;
  top: 40%;
  background-color: wheat;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
} 
.balloon:nth-child(19) {
  left: 77%;
  top: 40%;
  background-color: aquamarine;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
} 
.balloon:nth-child(20) {
  left: 82%;
  top: 40%;
  background-color: lightskyblue;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
} 
.balloon:nth-child(21) {
  left: 84%;
  top: 40%;
  background-color: lightcoral;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
} 
.balloon:nth-child(22) {
  left: 90%;
  top: 40%;
  background-color: lightgreen;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
} 
.balloon:nth-child(23) {
  left: 93%;
  top: 40%;
  background-color: azure;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
} 
.balloon:nth-child(24) {
  left: 95%;
  top: 40%;
  background-color: aqua;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
} 
html {
  scroll-behavior: smooth;
}

* {
box-sizing: border-box;
}

/* @font-face {
  font-family: 'Solway';
  font-family: 'Nunito';
  src: url('https://fonts.googleapis.com/css?family=Nunito|Solway&display=swap'); /* IE9 Compat Modes */
/* } */

body{
  font-family: 'Inria Serif', serif;
  margin: 0;
}

a, a:visited {
  color: #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

