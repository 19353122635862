.contact-section {
  width: 60%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: left;
}

.contact-section h1 {
  font-weight: 100;
  font-size: xx-large;
}

.contact-section  p {
  font-weight: 100;
  font-size: large;
  color: #5b5959;
}

.contact-social-icons {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.contact-social-icons img{
  width: 50px;
  opacity: 0.4;
}

.contact-social-icons img:hover{
  width: 50px;
  opacity: 1;
  transition: ease-in-out 300ms;
}



@media (max-width: 768px) {
  .contact-section  {
    width: 80%;
    margin-top: 25%
  }

  .contact-social-icons {
    width: 80%;
  }
}