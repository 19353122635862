.stories-page h1 {
  font-weight: 100;
  text-align: left;
}

.stories-section {
  margin-top: 10%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .stories-section {
    width: 80%;
    margin-top: 25%;
  }
}
