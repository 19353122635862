.header {
  display: flex;
  justify-content: flex-start;
  font-size: small;
  /* color: #000000; */
  padding: 0 20px;
  border-bottom: 1px solid #efefef;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
}

.header-dot {
  color: #2689f1;
  font-size: xx-large;
}
